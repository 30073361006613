<div class="main">
    <div class="row">
        <div class="col">
            <img class="logo" src="../../assets/Images/logo.png" alt="logo">
        </div>
        <div class="col-3">
            <div class=" d-flex justify-content-center pageHeader">
                <h3 id="main-header"><strong>Content Service Platform</strong></h3>
            </div>
            <div class="content justify-content-center">
                <h3 id="header"><strong>Sign Up</strong></h3>
                <div class="mt-4">

                    <div class="d-flex ">
                        <div class="input-group 
" >
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="leftLabel">First Name :</span>
                            </div>
                            <input type="text" class="form-control" id="rightLabel" aria-label="Username"
                                aria-describedby="basic-addon1">
                        </div>&nbsp;&nbsp;
                        <div class="input-group 
" >
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="leftLabel">Last Name :</span>
                            </div>
                            <input type="text" class="form-control" id="rightLabel" aria-label="Username"
                                aria-describedby="basic-addon1">
                        </div>
                    </div>
                    <div class="input-group ">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="leftLabel">Username :</span>
                        </div>
                        <input type="text" class="form-control" id="rightLabel" aria-label="Username"
                            aria-describedby="basic-addon1">
                    </div>
                    <div class="input-group ">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="leftLabel">Email :</span>
                        </div>
                        <input type="text" class="form-control" id="rightLabel" aria-label="Username"
                            aria-describedby="basic-addon1">
                    </div>
                    <div class="input-group ">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="leftLabel">Password :</span>
                        </div>
                        <input type="text" class="form-control" id="rightLabel" aria-label="Username"
                            aria-describedby="basic-addon1">
                    </div>
                    <div class="input-group ">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="leftLabel">Confirm Password :</span>
                        </div>
                        <input type="text" class="form-control" id="rightLabel" aria-label="Username"
                            aria-describedby="basic-addon1">
                    </div>


                </div>
                <div class="col row mb-4 ">
                    <div class="col p-0">
                        <div class=""> <a [routerLink]="['/login']" class="btn btn-primary btn-sm active" id="createBtn"
                                role="button" aria-pressed="true">Create Account</a>
                        </div>
                    </div>
                    <div class="col d-flex justify-content-end p-0">
                        <span [routerLink]="['/login']" style="color: rgb(13, 87, 156); cursor: pointer; color: #3155A6; padding: 0px; font-size:0.8333vw;">Existing User ?</span>
                    </div>
                </div>     

                <div class="col row p-0 mb-3">
                    <div class="col">
                        <span class="line1"></span>
                    </div>
                    <div class="col-1" id="or">Or</div>
                    <div class="col  pr-0">
                        <span class="line2"></span>
                    </div>
                </div>
                <div class="icon d-flex justify-content-center ">
                    <img src="../../assets/Images/google-logo.png" alt="">&nbsp;&nbsp;&nbsp;
                    <img src="../../assets/Images/JB front and backArtboard 2.png" class="custom-h1" alt="">
                    <img src="../../assets/Images/linkedin.png" alt="">&nbsp;&nbsp;&nbsp;
                </div>
            </div>
        </div>
        <div class="col">

        </div>


    </div>