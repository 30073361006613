<mat-sidenav-container class="example-container" autosize>
  <mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()"
    (mouseleave)="mouseleave()">
    <mat-nav-list>
      <div class="name text-center">
      </div>
      <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent mb-2 mt-4" [routerLink]="['/dashboard']">
        <i class="fas fa-home-lg-alt" [ngClass]="[isDashboard ? 'comp' : '']"></i>
        <span class="full-width mt-1" *ngIf="isExpanded || isShowing" [ngClass]="[isDashboard ? 'comp' : '']" style="margin-left: 0.5208333333333334vw !important;">Dashboard</span>
        <i class="fas fa-caret-left fa-3x arrow-icon2 d-flex align-items-end"*ngIf="isArrow1"></i>
      </mat-list-item>
      <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent mb-2" [routerLink]="['/summary']">
        <i class="fas fa-tasks" [ngClass]="[isTask ? 'comp' : '']"></i>
        <span class="full-width" *ngIf="isExpanded || isShowing" [ngClass]="[isTask ? 'comp' : '']" style="margin-left: 0.5208333333333334vw !important;">Summary Report</span>
      </mat-list-item>
      <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent mb-2" [routerLink]="['/detail']">
        <i class="fas fa-books" ></i>
   
        <span class="full-width" *ngIf="isExpanded || isShowing" style="margin-left: 0.5208333333333334vw !important;">Detail Report</span>
      </mat-list-item>
      <!-- <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent mb-2">
        <i class="fas fa-box"></i>
        <span class="full-width" *ngIf="isExpanded || isShowing">Products</span>
      </mat-list-item> -->
    </mat-nav-list>
    <mat-nav-list> </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>