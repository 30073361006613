<nav class="navbar p-0 navbar-expand  border-bottom box-shadow navbar-custom">
    <div class="col-12 row">
        <div class="col-2">
            <img src="../../assets/Images/icodex.png" id="logo" [routerLink]="['/login']">
        </div>
        <div class="col-4" style="margin-left: 20.833333333333332vw;">
            <h5 class="ml-5 mt-4" style="font-size: 1.7020833333333333vw !important; color: var(--unnamed-color-6b6b6b);
        text-align: left;
        font: normal normal bold 1.3020833333333333vw Product Sans;
        letter-spacing: 0px;
        color: #6B6B6B;
        opacity: 1;" id="head">Customer Quality Assurance
</h5>
        </div>
        <div class="col"></div>
         <div class="col-2">
            <nav class="navbar navbar-dark  navbar-expand-sm">              
                
                <div class="collapse navbar-collapse" id="navbar-list-4">
                  <ul class="navbar-nav">
                      <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="margin-left: 8.072916666666666vw;">
                        <i class="fas fa-circle"></i>                       
                    </a>
                      <div class="dropdown-menu menu1" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item dlist" href="#">Dashboard</a>
                        <a class="dropdown-item dlist" href="#">Edit Profile</a>
                        <a class="dropdown-item dlist" href="#">Log Out</a>
                      </div>
                    </li>   
                  </ul>
                </div>
              </nav>
        </div>   

    </div>


</nav>
