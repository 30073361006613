import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { AuthGuard } from './authentication/guards/auth.guard';
import { ForgotComponent } from '../core/forgot/forgot.component';
import { ResetComponent } from '../core/reset/reset.component';

const pagesRoutes: Routes = [
  {
    path:'dashboard',
    component: PagesComponent,loadChildren: () => import('./dashboard/dashboard.module')
    .then(m => m.DashboardModule),
    
  }, 
  {
    path:'summary',
    component: PagesComponent,loadChildren: () => import('./summary/summary.module')
    .then(m => m.SummaryModule),
  }, 
  {
    path:'summaryanalysis',
    component: PagesComponent,loadChildren: () => import('./summaryanalysis/summaryanalysis.module')
    .then(m => m.SummaryanalysisModule),
  },
  {
    path:'vendorremark',
    component: PagesComponent,loadChildren: () => import('./vendorremark/vendorremark.module')
    .then(m => m.VendorremarkModule),
  },
  {
    path:'detail',
    component: PagesComponent,loadChildren: () => import('./detail/detail.module')
    .then(m => m.DetailModule),
  }, 
  {
    path:'login',
    component: ForgotComponent
  },  
  {
  path: ':templatelink',
  component: PagesComponent,
  children: [
    // {
    //   path:'article',
    //   component: ArticleComponent
    // },
  ]
}
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(pagesRoutes)
  ],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
