import { Component, OnInit, Output,EventEmitter   } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
 
  public firstName:string = "--"
  public laststName:string = "--"
  public currentRoute;
  ishome:boolean=false;
  SearchValue:any;

  validtill:any =sessionStorage.getItem('validtill');
  GroupName:any= sessionStorage.getItem('GroupName');
  public lang:any;
  dir=sessionStorage.getItem("direction");

  constructor(private router: Router) { }

  
  ngOnInit(): void {   
    this.currentRoute = this.router;
    let currentUrl = this.currentRoute.url;
   
    this.ishome = ['/home',''].findIndex(str => currentUrl.includes(str)) == 0;   
    this.lang =sessionStorage.getItem('lang')
    this.firstName = sessionStorage.getItem('UserFname');
    this.laststName = sessionStorage.getItem('UserLname')
    if(this.ishome){
      this.validtill=null;
      this.GroupName=null;
    }  
   
  }



  logout(){
    // this.dataService.logout();
  }

//   PostData() {  
//     this.nameEmitter.emit(this.SearchValue);  
// }  
}


