<div class="main">
    <div class="row">
        <div class="col">
            <img class="logo" src="../../assets/Images/logo.png" alt="logo">
        </div>
        <div class="col-3 mt-5">
            <div class="d-flex justify-content-center pageHeader ">
                <h3><strong style="font-size: 1.4583333333333333vw;">Customer Quality Assurance</strong></h3>
            </div>
            <div class="content justify-content-center">
                <h3 id="header"><strong style="font-size: 1.4583333333333333vw;">Login</strong></h3>
                <div class="">

                    <form [formGroup]="loginform" (ngSubmit)="validateCaptcha1()">
                    <div class="input-group  mt-4">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="leftLabel">Username :</span>
                        </div>
                        <input type="text" class="form-control"  formControlName="Username" id="rightLabel" aria-label="Username"
                            aria-describedby="basic-addon1">
                    </div>
                    <div class="input-group ">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="leftLabel">Password :</span>
                        </div>
                        <input type="password" class="form-control" formControlName="Password" id="rightLabel" aria-label="Password"
                            aria-describedby="basic-addon2">
                    </div>
                    </form>
                    <div class="card captchaCol d-flex justify-content-center" style="height: 2.5em;">
                        <input type="" class="blur noselect" id="txtCaptcha1" onmousedown="return false"
                            onselectstart='return false;' />
                    </div>
                    <div class="input-group ">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="leftLabel">Captcha :</span>
                        </div>
                        <input type="text" class="form-control" id="rightLabel" aria-label="Username"
                            aria-describedby="basic-addon1" id="txtCompare1" required>
                    </div>

                </div>
                <div class="row ">
                    <div class="col">
                        <a class="btn btn-primary btn-sm active" id="createBtn" role="button" aria-pressed="true"
                        (click)="validateCaptcha1()">Login</a>
                    </div>
                  <div class="col">
                    <a href="#" id="createBtn " class="forgot-text d-flex justify-content-end  " aria-pressed="true" >Forgot
                        Password?</a>
                  </div>
                   
                </div>


               
                <div class="col row p-0">
                    <div class="col">
                        <span class="line1"></span>
                    </div>
                    <div class="col-1" id="or">Or</div>
                    <div class="col  pr-0">
                        <span class="line2"></span>
                    </div>
                </div>
                <div class="icon d-flex justify-content-center ">
                    <img src="../../assets/Images/google-logo.png" alt="">&nbsp;&nbsp;&nbsp;
                    <img src="../../assets/Images/JB front and backArtboard 2.png" class="custom-h1" alt="">
                    <img src="../../assets/Images/linkedin.png" alt="">&nbsp;&nbsp;&nbsp;
                </div>
            </div>
        </div>
        <div class="col">

        </div>
    </div>