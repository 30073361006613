<div class="container-fluid p-0 master">
    <div class="">
        <app-header></app-header>
    </div>
    <div class="container-fluid h-100 d-inline-block pr-0" style="width:98.8% !important;"    >
        <div class="row">
            <nav class="col-md-1-5  pl-auto p-0 sidebar">
                <app-menubar class=""></app-menubar>
            </nav>
            <div class="col col-md">
                <div class=" row">
                    <main role="main" class="col main">
                        <router-outlet (activate)='onActivate()'></router-outlet>
                    </main>
                    <!-- <div class="col-md-2-5 d-flex justify-content-start p-0">
                        <app-right-panel  class="">
                        </app-right-panel>
                    </div> -->
                    
                </div>
                <!-- <div class="col-12"> -->
                    <!-- </div> -->
                    
                    <!-- <footer class="col-12 footer" style="background: #EAEEF2 0% 0% no-repeat padding-box; margin-left: -15px;">
                        <app-footer></app-footer>
                    </footer> -->
            </div>      
                     
        </div>
        <div class="row">
            <div class="col-md-1-5">

            </div>
            <div class="col-md p-0 ">
                <footer class="footer d-flex justify-content-end" style="background: #EAEEF2 0% 0% no-repeat padding-box;">
                    <app-footer></app-footer>
                </footer>
            </div>

        </div>
    </div>
</div>


